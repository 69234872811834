import React, { useEffect, useState } from "react";
import HeroArea from "./HeroArea";
import PopularEventArea from "./PopularEventArea";
import { sortEventsDesc } from "../../../utils/events";

const HomePage = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/events?populate[address][populate][0]=city&populate[contact][populate]=*&populate[listImage][populate]=*&populate[detailsImage][populate]=*&populate[thumbnailImage][populate]=*`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });
        const data = await response.json();
        data?.data?.sort(sortEventsDesc);
        setEvents(data?.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setIsLoading(false);
      }
    };

    setTimeout(fetchEvents,2000)
  }, []); // Empty dependency array to fetch events only once
  
  const sliderEvents = events.slice(0,3)
  return (
    <div className="homepage">
      {/* */}
      <div className="preloader">
        {isLoading && (
          <div className="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
      </div>
      {/* */}
      {/* all section component import start */}
      <HeroArea events={sliderEvents} />
      <PopularEventArea events={events} />
      {/* all section component import start */}
    </div>
  );
};

export default HomePage;