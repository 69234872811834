import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
/*image light box*/
import SimpleReactLightbox from "simple-react-lightbox";
/*import wrapping layout*/
// layout default import from compnent
import MainLayout from "./components/layout/Main";
/*import all pages from components*/
import Error from "./components/pages/404/Error";
import About from "./components/pages/about/About";
import EventDetails from "./components/pages/events/eventDetails/EventDetails";
import Events from "./components/pages/events/Events";
/*initialization All css*/
import "./index.css";
import Services from "./components/pages/services/Services";
import ServiceDetails from "./components/pages/services/ServiceDetails";
import Competitions from "./components/pages/competitions/Competitions";
import CompetitionDetails from "./components/pages/competitions/CompetitionDetails";

// default Warning Error hide

/*
=>version : 0.1
=>Event : Rendering al content to web
=>Action: define all routes and page
@return HTML
*/

class Root extends React.Component {
  render() {
    return (
      <>
        <BrowserRouter basename="/">
          <Switch>
            <Route exact path="/" component={MainLayout} />

            <Route
                path={`${process.env.PUBLIC_URL}/about`}
                component={About}
              />
            
              <Route
                path={`${process.env.PUBLIC_URL}/services/:serviceId`}
                component={ServiceDetails}
              />
               <Route
                path={`${process.env.PUBLIC_URL}/services`}
                component={Services}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/competitions/:competitionId`}
                component={CompetitionDetails}
              />
                <Route
                path={`${process.env.PUBLIC_URL}/competitions`}
                component={Competitions}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/events/:eventId`}
                component={EventDetails}
              />
               <Route
                path={`${process.env.PUBLIC_URL}/events`}
                component={Events}
              />
           
              <Route
                path="*"
                component={Error}
              />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Root />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
