import React from "react";
import { Link } from "react-router-dom";

const Service = ({ serviceData }) => {
  const { id, attributes } = serviceData;
  const { address, companyName, listImage, serviceType } = attributes;
  const scrollTop = () => {
    sessionStorage.setItem("selectedService", JSON.stringify(serviceData));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      key={id}
      className="col-lg-4 col-md-6 wow fadeInUp animated"
      data-wow-delay="200ms"
      data-wow-duration="1500ms"
    >
      <div className="blog-card-md">
        <div className="blog-thumb">
          <Link to={`${process.env.PUBLIC_URL}/services/${id}`}>
            <img
              src={`${process.env.REACT_APP_BASE_URL}${listImage?.data?.attributes?.url}`}
              alt="Imgs"
            />
          </Link>
          <div className="blog-tags">
            <Link to={`${process.env.PUBLIC_URL}/services/${id}`}>
              {serviceType?.data?.attributes?.name}
            </Link>
          </div>
        </div>
        <div className="blog-content">
          <h4 className="blog-title">
            <Link
              onClick={scrollTop}
              to={`${process.env.PUBLIC_URL}/services/${id}`}
            >
              {companyName}
            </Link>
          </h4>
          <div className="blog-bottom">
            <div className="blog-date">
              <p>
                <i className="bi bi-pin-map" />
                <span>
                  {address.city.data.attributes.name}, {address.street}
                </span>
              </p>
            </div>
            {/* <div className="blog-date">
              <p>
                <i className="bi bi-telephone" />
                <span>
                  {contact.name}, {contact.number}
                </span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Service;
