import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Competition = ({ competitionData }) => {
  const { id, attributes } = competitionData;
  const { title, listImage, startDate } = attributes;
  const scrollTop = () => {
    sessionStorage.setItem(
      "selectedCompetition",
      JSON.stringify(competitionData)
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      key={id}
      className="col-lg-4 col-md-6 wow fadeInUp animated"
      data-wow-delay="200ms"
      data-wow-duration="1500ms"
    >
      <div className="blog-card-md">
        <div className="blog-thumb">
          <Link
            onClick={scrollTop}
            to={`${process.env.PUBLIC_URL}/competitions/${id}`}
          >
            <img
              src={`${process.env.REACT_APP_BASE_URL}${listImage?.data?.attributes?.url}`}
              alt="Imgs"
            />
          </Link>
          <div className="blog-tags">
            <Link
              onClick={scrollTop}
              to={`${process.env.PUBLIC_URL}/competitions/${id}`}
            >
              {title}
            </Link>
          </div>
        </div>
        <div className="blog-content">
          <h4 className="blog-title">
            <Link
              onClick={scrollTop}
              to={`${process.env.PUBLIC_URL}/competitions/${id}`}
            >
              {title}
            </Link>
          </h4>
          <div className="blog-bottom">
            <div className="blog-date">
              <p>
                <i className="bi bi-calendar2" />
                <span>{moment(startDate).format("DD MMM, YY")}</span>
              </p>
            </div>
            {/* <div className="blog-date">
              <p>
                <i className="bi bi-telephone" />
                <span>
                  {contact.name}, {contact.number}
                </span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Competition;
