import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
import Event from "../../common/Event";
import LayoutHeader from "../../common/LayoutHeader";
import Footer from "../../common/LayoutFooter";
import { sortEventsDesc } from "../../../utils/events";

// image import
const Events = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeCityId, setActiveCityId] = useState(-1);


  useEffect(() => {
    const fetchEvents = async () => {
      try {
        new WOW.WOW().init();
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/events?populate[address][populate][0]=city&populate[contact][populate]=*&populate[listImage][populate]=*&populate[detailsImage][populate]=*&populate[thumbnailImage][populate]=*`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        data?.data?.sort(sortEventsDesc);
        setEvents(data.data);
        setFilteredEvents(data.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCities = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/cities?populate=*`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        setCities(data.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchEvents()
    fetchCities()
  }, []); // Empty dependency array to fetch events only once
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const filterByBusiness = (id) => {
    const res = events.filter(
      (x) => x.attributes.address.city.data.id === id
    );
    setFilteredEvents(res);
    setActiveCityId(id);
  };

  return (
      isLoading ? (<div className="preloader">
          <div className="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
      </div>):( <>
        <LayoutHeader activate="events"/>
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  <h1>Events</h1>
                </div>
                <h2 className="page-title">Events</h2>
                <ul className="page-switcher">
                  <li>
                    <Link
                      onClick={() => scrollTop}
                      to={`${process.env.PUBLIC_URL}/`}
                    >
                      Home <i className="bi bi-caret-right" />
                    </Link>
                  </li>
                  <li>Events</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event-area">
        <div className="container position-relative pt-110">
          <div className="row">
            <div className="col-lg-12">
              <div className="background-title text-style-one">
                <h2>Events</h2>
              </div>
              <div className="section-head">
                <h3>Events</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="event-category-buttons">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                   {cities?.map((x, i) => (
                  <li className="nav-item" role="presentation">
                   <button
                          className={
                            activeCityId === x.id
                              ? "nav-link active"
                              : "nav-link "
                          }
                          id="pills-tab1"
                          type="button"
                          role="tab"
                          onClick={() => filterByBusiness(x.id)}
                        >
                      {x?.attributes.name}
                      <span>
                            {
                              events?.filter(
                                (x) =>
                                  x?.attributes?.address?.city?.data.id === activeCityId
                              ).length
                            }
                          </span>
                    </button>
                  </li>))}

                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="all-event-cards">
                <div className="tab-content" id="pills-tabContent">
                  {events.length > 0 && (
                    <div
                      className="tab-pane fade show active"
                      id="pills-event1"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      <div className="row">
                        {filteredEvents.map((event, index) => (
                          <Event key={index} eventData={event} />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>)
          
  );
};

export default Events;
