import React, { useEffect } from "react";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
import Event from "../../common/Event";

const PopularEventArea = ({ events = [] }) => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <>
      {/* ===============  Event Area start  =============== */}
      <div className="event-area">
        <div className="container position-relative pt-110">
          <div className="row">
            <div className="col-lg-12">
              <div className="background-title text-style-one">
                <h2>Event</h2>
              </div>
              <div className="section-head">
                <h3>Popular Events</h3>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-lg-12">
              <div className="event-category-buttons">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-tab1"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-event1"
                      type="button"
                      role="tab"
                      aria-controls="pills-event1"
                      aria-selected="true"
                    >
                      Business <span>10</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-tab2"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-event2"
                      type="button"
                      role="tab"
                      aria-controls="pills-event2"
                      aria-selected="false"
                    >
                      Fire Wall <span>10</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-tab3"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-event3"
                      type="button"
                      role="tab"
                      aria-controls="pills-event3"
                      aria-selected="false"
                    >
                      Sport <span>10</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-tab4"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-event4"
                      type="button"
                      role="tab"
                      aria-controls="pills-event4"
                      aria-selected="false"
                    >
                      Web Development <span>10</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-tab5"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-event5"
                      type="button"
                      role="tab"
                      aria-controls="pills-event5"
                      aria-selected="false"
                    >
                      Marketing <span>10</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-tab6"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-event6"
                      type="button"
                      role="tab"
                      aria-controls="pills-event6"
                      aria-selected="false"
                    >
                      Technology<span>10</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-12">
              <div className="all-event-cards">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-event1"
                    role="tabpanel"
                    aria-labelledby="pills-tab1"
                  >
                    {events.length > 0 && (
                      <div className="row">
                        {events.map((event, index) => (
                          <Event key={index} eventData={event} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  Event Area end  =============== */}
    </>
  );
};

export default PopularEventArea;
