import React, { useEffect, useState } from "react";
import BreadCrumb from "./BreadCrumb";
import EventDetailsWrapper from "./EventDetailsWrapper";
import Footer from "../../../common/LayoutFooter";
import LayoutHeader from "../../../common/LayoutHeader";
import { useParams } from "react-router-dom";
const EventDetails = () =>  {

  const [evt, setEvent] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const {eventId} = useParams()

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/events/${eventId}?populate[address][populate][0]=city&populate[contact][populate]=*&populate[listImage][populate]=*&populate[detailsImage][populate]=*&populate[thumbnailImage][populate]=*`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        setEvent(data.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEvent()
  }, []);


    return (
        isLoading ? (
          <div className="preloader">
          <div className="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          </div>
        ):(<>
        <LayoutHeader activate="events"/>
        <BreadCrumb  evt={evt} />
        <EventDetailsWrapper evt={evt} />
        <Footer/>
        </>)
    );
}

export default EventDetails;
