import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { sortEventsDesc } from "../../../../utils/events";
import moment from "moment";

const EventRightArea = ({evt}) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/events?populate[address][populate][0]=city&populate[contact][populate]=*&populate[listImage][populate]=*&populate[detailsImage][populate]=*&populate[thumbnailImage][populate]=*`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        data?.data?.sort(sortEventsDesc);
        setEvents(data?.data.slice(0, 5));
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEvents();
  }, []); // Empty dependency array to fetch events only once

  return (
    <>
      <div className="col-xl-4">
        <div className="event-d-sidebar">
          <div className="event-d-sidebar-cards">
            <div className="category-title">
              <i className="bi bi-layout-text-sidebar-reverse" />
              <h4>Recent Events</h4>
            </div>
            <ul className="event-cards-list">
              {events.filter(x=> x.id !== evt.id)?.map((x, i) => (
                <li key={i} className="event-card-sm">
                  <div className="event-thumb">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${x?.attributes?.thumbnailImage?.data?.attributes?.url}`}
                      alt="Imgs"
                    />
                  </div>
                  <div className="event-sm-info">
                    <h5>{x.attributes?.title}</h5>
                    <div className="event-bottom">
                      <div className="event-date">
                        <i className="bi bi-calendar2-week" />{" "}
                        {moment(x.attributes?.startDate).format("DD MMM, YY")}
                      </div>
                    </div>
                    <div className="event-d-btn">
                      <Link
                        target="_blank"
                        to={`${process.env.PUBLIC_URL}/events/${x.id}`}
                      >
                        More
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* <div className="event-organizer-wrap">
              <div className="category-title">
                <i className="bi bi-person-plus" /> <h4>Event Organized By</h4>
              </div>
              <div className="organizer-info">
                <div className="organizer-image">
                  <img src={OrgabizerIMG}  alt="Imgs" />
                </div>
                <h4>Matthew Luke</h4>
                <h5>Event Lab</h5>
                <div className="organizer-signature">
                  <img src={SignatureImg}  alt="Imgs" />
                </div>
              </div>
            </div> */}
          {/* <div className="event-sidebar-banner">
              <Link onClick={scrollTop} to={"#"}>
                <img src={EventBanner}  alt="Imgs" className="img-fluid" />
              </Link>
            </div> */}
        </div>
      </div>
    </>
  );
};

export default EventRightArea;
