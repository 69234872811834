import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// image import
import { useParams } from "react-router-dom";
import LayoutHeader from "../../common/LayoutHeader";
import Footer from "../../common/LayoutFooter";
import moment from "moment";
const CompetitionDetails = () => {
  const [competition, setCompetition] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { competitionId } = useParams();

  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/competitions/${competitionId}?populate=*`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        setCompetition(data.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCompetition();
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return isLoading ? (
    <div className="preloader">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  ) : (
    <>
      <LayoutHeader activate="competitions" />
      {/* ===============  breadcrumb area start =============== */}
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  <h1>{competition?.attributes?.title}s</h1>
                </div>
                <h2 className="page-title">{competition?.attributes?.title}s</h2>
                <ul className="page-switcher">
                  <li>
                    <Link
                      onClick={() => scrollTop()}
                      to={`${process.env.PUBLIC_URL}/`}
                    >
                      Home <i className="bi bi-caret-right" />
                    </Link>
                  </li>
                  <li>{competition?.attributes?.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}
      {/* =============== Blog area start =============== */}
      <div className="blog-details-wrapper ">
        <div className="container position-relative pt-110">
          <div className="background-title">
            <h2>{competition?.attributes?.title}</h2>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details">
                <div className="blog-d-top">
                  <h2 className="blog-d-title">
                    <Link>{competition?.attributes?.title}</Link>
                  </h2>
                  <div className="blog-top-info">
                    <Link className="writer">
                      <i className="bi bi-telephone" />
                      {competition?.attributes?.contact.name},{" "}
                      {competition?.attributes?.contact.number}
                    </Link>
                    <Link className="blog-date">
                      <i className="bi bi-calendar2" />
                      <span>{moment(competition?.attributes?.startDate).format("MMMM DD, YYYY")}</span>
                    </Link>
                    <Link className="blog-date">
                      <i className="bi bi-calendar" />
                      <span>{moment(competition?.attributes?.endDate).format("MMMM DD, YYYY")}</span>
                    </Link>
                    <Link
                      to={{ pathname: competition?.attributes?.link }}
                      target="_blank"
                      className="blog-tag"
                    >
                      <i className="bi bi-link" />
                     Link
                    </Link>
                  </div>
                  <div className="blog-d-thumb">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${competition?.attributes?.detailsImage.data.attributes.url}`}
                      alt="Imgs"
                    />
                  </div>
                </div>
                <div className="blog-body">
                  <p style={{whiteSpace:'pre-line'}}>{competition?.attributes?.description}</p>
                  <p style={{whiteSpace:'pre-line'}}>{competition?.attributes?.howToEnter}</p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4">
              <div className="blog-sidebar">
                <div className="sb-social-icon">
                  <div className="category-title">
                    <i className="bi bi-plus-circle" /> <h4>Follow</h4>
                  </div>
                  <ul className="sb-social-icon-list">
                    <li>
                      <Link onClick={() => scrollTop()} to={"#"}>
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => scrollTop()} to={"#"}>
                        <i className="fab fa-instagram" />
                      </Link>
                    </li>

                    <li>
                      <Link onClick={() => scrollTop()} to={"#"}>
                        <i className="fab fa-linkedin-in" />
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => scrollTop()} to={"#"}>
                        <i className="fab fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link onClick={() => scrollTop()} to={"#"}>
                        <i className="fab fa-whatsapp" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* =============== Blog area end =============== */}
      <Footer />
    </>
  );
};

export default CompetitionDetails;
