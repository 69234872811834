import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import { useParams } from "react-router-dom";
import LayoutHeader from "../../common/LayoutHeader";
import { SRLWrapper } from "simple-react-lightbox";
import Footer from "../../common/LayoutFooter";

const ServiceDetails = () => {
  const [service, setService] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { serviceId } = useParams();

  useEffect(() => {
    const fetchService = async () => {
      try {
        new WOW.WOW().init();
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/services/${serviceId}?populate[address][populate][0]=city&populate[contact][populate]=*&populate[serviceType][populate]=*&populate[businessType][populate]=*&populate[listImage][populate]=*&populate[detailsImage][populate]=*&populate[gallery][populate]=*&populate[Services][populate]=*`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        setService(data.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching services:", error);
      }
    };
    fetchService();
  }, []); // Empty dependency array to fetch events only once

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const images = service?.attributes?.gallery?.data  || []

  // const options = {
  //   settings: {
  //     autoplaySpeed: 1000,
  //     boxShadow: 'none',
  //     disableKeyboardControls: false,
  //     disablePanzoom: true,
  //     disableWheelControls: true,
  //     hideControlsAfter: 3000,
  //     lightboxTransitionSpeed: 0.3,
  //     lightboxTransitionTimingFunction: 'linear',
  //     overlayColor: 'rgba(30, 30, 30, 0.9)',
  //     slideAnimationType: 'fade',
  //     slideSpringValues: [300, 50],
  //     slideTransitionSpeed: 0.6,
  //     slideTransitionTimingFunction: 'linear',
  //     usingPreact: false
  //   }
  // }

  return isLoading ? (
    <div className="preloader">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  ) : (
    <>
      <LayoutHeader activate="services" />
      {/* ===============  breadcrumb area start =============== */}
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  <h1>{service?.attributes?.companyName}</h1>
                </div>
                <h2 className="page-title">
                  {service?.attributes?.companyName}
                </h2>
                <ul className="page-switcher">
                  <li>
                    <Link
                      onClick={() => scrollTop()}
                      to={`${process.env.PUBLIC_URL}/`}
                    >
                      Home <i className="bi bi-caret-right" />
                    </Link>
                  </li>
                  <li>{service?.attributes?.companyName}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}
      {/* =============== Blog area start =============== */}
      <div className="blog-details-wrapper ">
        <div className="container position-relative pt-110">
          <div className="background-title">
            <h2>{service?.attributes?.companyName}</h2>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details">
                <div className="blog-d-top">
                  <h2 className="blog-d-title">
                    <Link >
                      {service?.attributes?.companyName}
                    </Link>
                  </h2>
                  <div className="blog-top-info">
                    <Link
                      className="writer"
                    >
                      <i className="bi bi-telephone" />{" "}
                      {service?.attributes?.contact.name},{" "}
                      {service?.attributes?.contact.number}
                    </Link>
                    <Link
                      className="blog-tag"
                    >
                      <i className="bi bi-pin-map" />{" "}
                      {
                        service?.attributes?.address?.city?.data?.attributes
                          ?.name
                      }
                      , {service?.attributes?.address?.street}
                    </Link>
                   {service?.attributes?.videoLink && <Link
                      to={{ pathname: service?.attributes?.videoLink }}
                      target="_blank"
                      className="blog-tag"
                    >
                      <i className="bi bi-youtube" />
                      YouTube
                    </Link>}
                    {service?.attributes?.mapsLink &&<Link
                      to={{ pathname: service?.attributes?.mapsLink }}
                        target="_blank"
                      className="blog-tag"
                    >
                      <i className="bi bi-map" /> maps
                    </Link>}
                    {service?.attributes?.link && <Link
                      to={{ pathname: service?.attributes?.link }}
                        target="_blank"
                      className="blog-tag"
                    >
                      <i className="bi bi-link" /> Link
                    </Link>}
                  </div>
                  <div className="blog-d-thumb">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${service?.attributes?.detailsImage?.data?.attributes.url}`}
                      alt="Imgs"
                    />
                  </div>
                </div>
                <div className="ed-main-wrap">
                  <div className="ed-tabs-wrapper">
                    <div className="tabs-pill">
                      <ul
                        className="nav nav-pills justify-content-center"
                        id="pills-tab2"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-details-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-details"
                            type="button"
                            role="tab"
                            aria-controls="pills-details"
                            aria-selected="true"
                          >
                            <i className="bi bi-info-circle" />
                            <span>Details</span>
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-gallary-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-gallary"
                            type="button"
                            role="tab"
                            aria-controls="pills-gallary"
                            aria-selected="false"
                          >
                            <i className="bi bi-images" /> <span>Gallery</span>
                          </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-gallary-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-gallary"
                      type="button"
                      role="tab"
                      aria-controls="pills-gallary"
                      aria-selected="false">
                      <i className="bi bi-images" /> <span>Gallery</span>
                    </button>
                  </li> */}
                      </ul>
                    </div>
                    <div className="tab-content" id="pills-tabContent2">
                      <div
                        className="tab-pane fade show active"
                        id="pills-details"
                        role="tabpanel"
                        aria-labelledby="pills-details-tab"
                      >
                        <div className="details-tab-content">
                          <p style={{whiteSpace:'pre-line'}}>{service?.attributes?.description}</p>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-gallary"
                        role="tabpanel"
                        aria-labelledby="pills-gallary-tab"
                      >
                        <div className="gallary-tab-content">
                          <SRLWrapper>
                            <div className="row">
                              {images && images[0] && (
                                <div className="col-lg-6">
                                  <div className="gallary-item">
                                    <img
                                      src={`${process.env.REACT_APP_BASE_URL}${images[0]?.attributes?.url}`}
                                      alt="Gallery"
                                    />
                                    <Link
                                      to={`${process.env.REACT_APP_BASE_URL}${images[0]?.attributes?.url}`}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_BASE_URL}${images[0]?.attributes?.url}`}
                                        className="gallary-item-overlay"
                                        alt="GallaryIMAGE"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              )}
                              {images && images[1] && (
                                <div className="col-lg-6">
                                  <div className="gallary-item">
                                    <img
                                      src={`${process.env.REACT_APP_BASE_URL}${images[1]?.attributes?.url}`}
                                      alt="Gallery"
                                    />
                                    <Link
                                      to={`${process.env.REACT_APP_BASE_URL}${images[1]?.attributes?.url}`}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_BASE_URL}${images[1]?.attributes?.url}`}
                                        className="gallary-item-overlay"
                                        alt="GallaryIMAGE"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              )}
                              {images && images[2] && (
                                <div className="col-lg-6">
                                  <div className="gallary-item">
                                    <img
                                      src={`${process.env.REACT_APP_BASE_URL}${images[2]?.attributes?.url}`}
                                      alt="Gallery"
                                    />
                                    <Link
                                      to={`${process.env.REACT_APP_BASE_URL}${images[2]?.attributes?.url}`}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_BASE_URL}${images[2]?.attributes?.url}`}
                                        className="gallary-item-overlay"
                                        alt="GallaryIMAGE"
                                      />
                                    </Link>
                                  </div>
                                </div>
                                )}
                              {images && images[3] && (
                                <div className="col-lg-6">
                                  <div className="gallary-item">
                                    <img
                                      src={`${process.env.REACT_APP_BASE_URL}${images[3]?.attributes?.url}`}
                                      alt="Gallery"
                                    />
                                    <Link
                                      to={`${process.env.REACT_APP_BASE_URL}${images[3]?.attributes?.url}`}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_BASE_URL}${images[3]?.attributes?.url}`}
                                        className="gallary-item-overlay"
                                        alt="GallaryIMAGE"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              )}
                              {images && images[4] && (
                                <div className="col-lg-6">
                                  <div className="gallary-item">
                                    <img
                                      src={`${process.env.REACT_APP_BASE_URL}${images[4]?.attributes?.url}`}
                                      alt="Gallery"
                                    />
                                    <Link
                                      to={`${process.env.REACT_APP_BASE_URL}${images[4]?.attributes?.url}`}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_BASE_URL}${images[4]?.attributes?.url}`}
                                        className="gallary-item-overlay"
                                        alt="GallaryIMAGE"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </div>
                          </SRLWrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="blog-sidebar">
                <div className="sb-category">
                  <div className="category-title">
                    <i className="bi bi-briefcase" /> <h4>Services Offered</h4>
                  </div>
                  <ul className="category-list">
                    {service?.attributes.Services.map((x, index) => (
                      <li key={index}>
                        <Link key={index}>{x?.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== Blog area end =============== */}
      <Footer />
    </>
  );
};

export default ServiceDetails;
