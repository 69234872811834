import React from "react";
import EventDetailsLeftArea from "./EventDetailsLeftArea";
import EventRightArea from "./EventRightArea";
const EventDetailsWrapper = ({evt}) => {
  return (
    <>
      <div className="event-details-wrapper ">
        <div className="container pt-120 position-relative">
          <div className="background-title text-style-one">
            <h2>{evt?.attributes?.title}</h2>
          </div>
          <div className="row">
            {/* event detils child componet */}
            <EventDetailsLeftArea evt={evt} />
            <EventRightArea  evt={evt}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetailsWrapper;
