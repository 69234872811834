import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const Event = ({ eventData }) => {
  const { id, attributes } = eventData;
  const { address, listImage, title, startDate, ticketPrice } = attributes;
const scrollTop = () => {
    sessionStorage.setItem("selectedEvent", JSON.stringify(eventData));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="col-lg-4 col-md-6 wow fadeInUp animated"
      data-wow-delay="200ms"
      data-wow-duration="1500ms"
    >
      <div className="event-card-md">
        <div className="event-thumb">
        <Link to={`${process.env.PUBLIC_URL}/events/${id}`}>
          <img
            src={`${process.env.REACT_APP_BASE_URL}${listImage?.data?.attributes?.url}`}
            alt={`${process.env.REACT_APP_BASE_URL}${listImage?.data?.attributes?.name}`}
          />
          <div className="event-lavel">
            <i className="bi bi-tag" />
            <span>{ticketPrice === 0 ? 'Free': `N$ ${ticketPrice}`}</span>
          </div>
          </Link>
        </div>
        <div className="event-content">
          <div className="event-info">
            <div className="event-date">
              <Link onClick={scrollTop} to={"#"}>
                <i className="bi bi-calendar2-week" />{" "}
                {moment(startDate).format("DD MMMM YYYY")}
              </Link>
            </div>
            <div className="event-location">
              <Link onClick={scrollTop} to={"#"}>
                <i className="bi bi-geo-alt" />{" "}
                {address.city.data.attributes.name}
              </Link>
            </div>
          </div>
          <h5 className="event-title">
            <Link
              onClick={scrollTop}
              to={`${process.env.PUBLIC_URL}/events/${id}`}
            >
              {title}
            </Link>
          </h5>
          <div className="event-bottom">
            <div className="event-readme">
              <Link
                onClick={scrollTop}
                to={`${process.env.PUBLIC_URL}/events/${id}`}
              >
                More
              </Link>
            </div>
            {/* <div className="event-share-icons">
              <ul className="share-options">
                <li>
                  <Link onClick={scrollTop} to={"#"}>
                    <i className="fab fa-facebook-f" />
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"#"}>
                    <i className="fab fa-instagram" />
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"#"}>
                    <i className="fab fa-linkedin-in" />
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"#"}>
                    <i className="fab fa-twitter" />
                  </Link>
                </li>
              </ul>
              <div className="share-btn">
                <i className="bi bi-share" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Event;
