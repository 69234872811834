import React, {  } from "react";
import moment from "moment";
// page animation
import "../../../../assets/css/animate.css";
const EventDetailsLeftArea = ({evt}) => {
  return (
    <>
      <div className="col-xl-8">
        <div className="ed-main-wrap">
          <div className="ed-top">
            <div className="ed-thumb">
              <img
                src={`${process.env.REACT_APP_BASE_URL}${evt?.attributes?.detailsImage?.data?.attributes?.url}`}
                alt=""
              />
            </div>
            <ul className="ed-status">
              <li>
                <i className="bi bi-calendar2-week" />{" "}
                {moment(evt?.attributes?.startDate).format("DD MMM, YY")}
              </li>
              <li className="active">
                <i className="bi bi-tag" />
                <span>
                  N${" "}
                  {evt?.attributes?.ticketPrice === 0
                    ? "Free"
                    : evt?.attributes?.ticketPrice}
                </span>
              </li>
              <li>
                <i className="bi bi-pin-map" />
                {evt?.attributes?.address?.city?.data?.attributes?.name},{" "}
                {evt?.attributes?.address?.street}
              </li>
            </ul>
            <div className="event-info row align-items-center">
              <div className="col-lg-3 col-md-4">
                <div className="single-event-info">
                  <div className="info-icon">
                    <i className="bi bi-telephone" />
                  </div>
                  <div className="info-content">
                    <h5>Contact</h5>
                    <p>
                      {evt?.attributes?.contact?.name},{" "}
                      {evt?.attributes?.contact?.number}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="single-event-info">
                  <div className="info-icon">
                    <i className="bi bi-link" />
                  </div>
                  <div className="info-content">
                    <h5>Link</h5>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={evt?.attributes?.link}
                    >
                      {evt?.attributes?.link?.substring(0, 25)}..
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="single-event-info">
                  <div className="info-icon">
                    <i className="bi bi-link" />
                  </div>
                  <div className="info-content">
                    <h5>Map</h5>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={evt?.attributes?.mapsLink}
                    >
                      {evt?.attributes?.mapsLink?.substring(0, 25)}..
                    </a>
                  </div>
                </div>
              </div>
         
            </div>
          </div>
          <div className="ed-tabs-wrapper">
            <div className="tabs-pill">
              <ul
                className="nav nav-pills justify-content-center"
                id="pills-tab2"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-details-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-details"
                    type="button"
                    role="tab"
                    aria-controls="pills-details"
                    aria-selected="true"
                  >
                    {" "}
                    <i className="bi bi-info-circle" /> <span>Details</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-schedule-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-schedule"
                    type="button"
                    role="tab"
                    aria-controls="pills-schedule"
                    aria-selected="false"
                  >
                    <i className="bi bi-calendar3" /> <span>Schedule</span>
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-gallary-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-gallary"
                      type="button"
                      role="tab"
                      aria-controls="pills-gallary"
                      aria-selected="false">
                      <i className="bi bi-images" /> <span>Gallery</span>
                    </button>
                  </li> */}
              </ul>
            </div>
            <div className="tab-content" id="pills-tabContent2">
              <div
                className="tab-pane fade show active"
                id="pills-details"
                role="tabpanel"
                aria-labelledby="pills-details-tab"
              >
                <div className="details-tab-content">
                  <h3 className="ed-title">{evt?.attributes?.title}</h3>
                  <p style={{whiteSpace:'pre-line'}}>{evt?.attributes?.description}</p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-schedule"
                role="tabpanel"
                aria-labelledby="pills-schedule-tab"
              >
                <div className="schedule-tab-content">
                  <div className="schedule-wrapper">
                    <div className="event-date">
                      <h3>
                        {moment(evt?.attributes?.startDate).format(
                          "DD MMM, YY"
                        )}
                      </h3>
                    </div>
                    <div
                      className="single-schedule-card row wow fadeInLeft animated"
                      data-wow-delay="400ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="col-xl-5 col-lg-5 p-0">
                        <div className="single-schedule-left">
                          <div className="schedule-top">
                            <h4>
                              {moment(evt?.attributes?.startDate).format(
                                "DD MMM"
                              )}{" "}
                              -{" "}
                              {moment(evt?.attributes?.endDate).format(
                                "DD MMM"
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7 p-0">
                        <div className="single-schedule-right has-break">
                          <h3 className="break-type">See you there</h3>
                        </div>
                      </div>
                    </div>
                    {/* <div className="ticket-progress-wrap">
                      <div className="ticket-progressbar" />
                      <h4>
                        Available Seat: <span>390-500</span>
                      </h4>
                      <div className="ticket-book-btn">
                        <Link
                          onClick={() => scrollTop}
                          to={"#"}
                          className="primary-btn-fill"
                        >
                          Book Now
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetailsLeftArea;
