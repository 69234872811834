import React, { Component } from "react";
import AboutWrapper from "./AboutWrapper";
import AchivementArea from "./AchivementArea";
import Breadcrumb from "./Breadcrumb";
import Testimonial from "./Testimonial";
import LayoutHeader from "../../common/LayoutHeader";
import Footer from "../../common/LayoutFooter";

class About extends Component {
  render() {
    return (
      <>
      <LayoutHeader activate="about" />
        {/* all about section componet */}
        <Breadcrumb />
        <AboutWrapper />
        <AchivementArea />
        <Testimonial />
        <Footer/>
      </>
    );
  }
}

export default About;
