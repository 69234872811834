import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({evt}) => {
  return (
    <>
      {/* ===============  breadcrumb area start =============== */}
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  <h1>{evt?.attributes?.title}</h1>
                </div>
                <h2 className="page-title">{evt?.attributes?.title}</h2>
                <ul className="page-switcher">
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/`}>
                      Home <i className="bi bi-caret-right" />
                    </Link>
                  </li>
                  <li>{evt?.attributes.title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}
    </>
  );
};

export default BreadCrumb;
