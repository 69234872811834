import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
import Competition from "../../common/Competition";
import LayoutHeader from "../../common/LayoutHeader";
import Footer from "../../common/LayoutFooter";

// image import
const Competitions = () => {
  const [competitions, setCompetitions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCompetitions = async () => {
      try {
        new WOW.WOW().init();
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/competitions?populate=*`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        console.log("data-ser", data);
        setCompetitions(data.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchCompetitions();

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []); // Empty dependency array to fetch events only once
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return isLoading ? (
    <div className="preloader">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  ) : (
    <>
      <LayoutHeader activate="competitions" />
      {/* ===============  breadcrumb area start =============== */}
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  <h1>Competitions</h1>
                </div>
                <h2 className="page-title">Competitions</h2>
                <ul className="page-switcher">
                  <li>
                    <Link
                      onClick={() => scrollTop()}
                      to={`${process.env.PUBLIC_URL}/`}
                    >
                      Home <i className="bi bi-caret-right" />
                    </Link>
                  </li>
                  <li>Competitions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}
      {/* =============== Blog area start =============== */}
      <div className="blog-sidebar-wrapper ">
        <div className="container position-relative pt-110">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="background-title text-style-one">
                <h2>Competitions</h2>
              </div>
              <div className="section-head">
                <h3>Competitions</h3>
              </div>
            </div>
          </div>
            <div className="col-lg-12">
              <div className="row">
                {competitions.map((competition, index) => (
                  <Competition key={index} competitionData={competition} />
                ))}
              </div>
            </div>
            {/* <div className="col-lg-4">
                <div className="blog-sidebar">
                  <div className="sb-category">
                    <div className="category-title">
                      <i className="bi bi-list-task" /> <h4>Service Type</h4>
                    </div>
                    <ul className="category-list">
                      {serviceTypes.map((svcType, index) => (
                        <li key={index}>
                          <Link
                            key={index}
                            onClick={() => filterByService(svcType.id)}
                            to={"#"}
                          >
                            {svcType?.attributes?.name}
                            <div className="category-lavel">
                              <span>
                                {
                                  services?.filter(
                                    (x) =>
                                      x?.attributes?.serviceType?.data?.id ===
                                      svcType.id
                                  ).length
                                }
                              </span>
                              <i className="bi bi-box-arrow-right" />
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="sb-tags">
                    <div className="category-title">
                      <i className="bi bi-tags" /> <h4>Business Type</h4>
                    </div>
                    <ul className="tag-list">
                      {businessTypes.map((bsType, index) => (
                        <li key={index}>
                          <Link
                            key={index}
                            onClick={() => filterByBusiness(bsType.id)}
                            to={"#"}
                          >
                            {bsType?.attributes?.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Competitions;
