export function sortEventsDesc(a, b) {
  const dateA = new Date(a.attributes.startDate);
  const dateB = new Date(b.attributes.startDate);

  return dateB.getTime() - dateA.getTime();
}

export function sortEventsAsc(a, b) {
  const dateA = new Date(a.attributes.startDate);
  const dateB = new Date(b.attributes.startDate);

  return dateA.getTime() - dateB.getTime();
}

