import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
import Service from "../../common/Service";
import LayoutHeader from "../../common/LayoutHeader";
import Footer from "../../common/LayoutFooter";

// image import
const Services = () => {
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeBusinessId, setActiveBusinessId] = useState(-1);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        new WOW.WOW().init();
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/services?populate[address][populate][0]=city&populate[contact][populate]=*&populate[serviceType][populate]=*&populate[businessType][populate]=*&populate[listImage][populate]=*&populate[detailsImage][populate]=*&populate[gallery][populate]=*`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        console.log("data-ser", data);
        setServices(data.data);
        setFilteredServices(data.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    const fetchServicesTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/service-types`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        setServiceTypes(data.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    const fetchBusinessTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/business-types`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        setBusinessTypes(data.data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
    fetchBusinessTypes();
    fetchServicesTypes();
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []); // Empty dependency array to fetch events only once

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  const filterByBusiness = (id) => {
    const res = services.filter(
      (x) => x.attributes.businessType.data.id === id
    );
    setFilteredServices(res);
    setActiveBusinessId(id);
  };
  return isLoading ? (
    <div className="preloader">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  ) : (
    <>
      <LayoutHeader activate="services" />
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  <h1>Services</h1>
                </div>
                <h2 className="page-title">Services</h2>
                <ul className="page-switcher">
                  <li>
                    <Link
                      onClick={() => scrollTop()}
                      to={`${process.env.PUBLIC_URL}/`}
                    >
                      Home <i className="bi bi-caret-right" />
                    </Link>
                  </li>
                  <li>Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}
      {/* =============== Blog area start =============== */}
      <div className="blog-sidebar-wrapper ">
        <div className="container position-relative pt-110">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="background-title text-style-one">
                <h2>Services</h2>
              </div>
              <div className="section-head">
                <h3>Services</h3>
              </div>
            </div>
          </div>
          {!isLoading && (
            <div className="row">
              <div className="col-lg-12">
                <div className="event-category-buttons">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    {businessTypes?.map((x, i) => (
                      <li key={i} className="nav-item" role="presentation">
                        <button
                          className={
                            activeBusinessId === x.id
                              ? "nav-link active"
                              : "nav-link "
                          }
                          id="pills-tab1"
                          type="button"
                          role="tab"
                          onClick={() => filterByBusiness(x.id)}
                        >
                          {x.attributes?.name}{" "}
                          <span>
                            {
                              services?.filter(
                                (x) =>
                                  x?.attributes?.businessType?.data?.id === activeBusinessId
                              ).length
                            }
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  {filteredServices.map((service, index) => (
                    <Service key={index} serviceData={service} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
