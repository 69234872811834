/**
 * Footer.jsx
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2023 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* ===============  footer area start  =============== */}
      <div className="footer-outer pt-120 ">
        <div className="footer-area">
          <div className="container">
            <div className="footer-bottom">
              <div className="row align-items-center">
                <div className="col-lg-5  order-3 order-lg-1">
                  <div className="footer-copyright">
                    <p>
                      © {new Date().getFullYear()} HypeNa | Created By{" "}
                      <Link to={"#"}>
                        Logic Plus Information Technologies CC
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 order-1 order-lg-2">
                  <div className="footer-logo">
                    <Link onClick={scrollTop} to={"#"}>
                      <img src="assets/images/logo-v2.png" alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 order-2 order-lg-3">
                  <ul className="d-flex footer-social-links justify-content-lg-end justify-content-center">
                    <li>
                      <Link onClick={scrollTop} to={"#"}>
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={"#"}>
                        <i className="fab fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={"#"}>
                        <i className="fab fa-linkedin-in" />
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={"#"}>
                        <i className="fab fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={"#"}>
                        <i className="fab fa-whatsapp" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  footer area end  =============== */}
    </>
  );
};

export default Footer;